import React from 'react';
import { AlwaysLearning, HealthCare, StayFresh, Time } from '@illustrations';
import { Highlight, Highlight2 } from './children/SectionOurApproach.styled';

const jobOfferData = {
  sectionOurApproach: {
    title: 'Our approach',
    description: `So, you’re considering a job at Ragnarson?
    It's in our best interest to help you find out whether Ragnarson is the right place for you.
    Deciding how we will be spending 6 to 10 hours of our lives together, five times a week, is paramount.
    It shouldn't be down to a simple benefit versus requirements comparison.`,
    block1: {
      title: {
        desktop: (
          <>
            {'There is no point in running yet another '}
            <Highlight>software development</Highlight>
            {' company without a good reason.'}
          </>
        ),
        mobile: (
          <>
            {'There is no point in running yet another software '}
            <Highlight>development</Highlight> company without a good reason.
          </>
        ),
      },
      description: `For us, it's how we treat others as we would like to be treated (read our story).
      That's why at Ragnarson we have created an environment where people achieve their fullest potential and are able to express themselves through their work.
      Here are our core values. If you don’t see yourself in our story, Ragnarson is probably not the place you are looking for.`,
    },
    block2: {
      title: {
        desktop: <>In order to foster this environment, the company has transparency at its core.</>,
        mobile: (
          <>
            {'In order to foster this environment, the company has '}
            <Highlight2>transparency</Highlight2> at its core.
          </>
        ),
      },
      description: `All staff has access to our complete financial records, for example, expenses, income, client/employees rates etc.
      All of the processes, decisions and documents around them are open too.
      As uncle Ben said, “With great power comes great responsibility.”
      So you will need all these details to make various decisions at the company - whether or not to recruit somebody, accept or reject a client's project, give feedback to others, allocate the profit or save it for the future.
      Making such calls will help you develop and broaden your perspective.`,
    },
    block3: {
      title: {
        desktop: (
          <>
            {`Obviously, you will be working with our clients on their products too.
            But that’s definitely not all there is to `}
            <Highlight2>working</Highlight2>at Ragnarson.
          </>
        ),
        mobile: (
          <>
            {'Obviously, you will be working with our '}
            <Highlight2>clients</Highlight2>
            {' on their products too. But that’s definitely not all there is to working at Ragnarson'}
          </>
        ),
      },
      description: `Given you're still reading this and you haven’t seen the salary yet
      (because there is none in this narrative), your motivations are not strictly financial.`,
    },
    block4: {
      title: {
        desktop: (
          <>That&apos;s great because for us the money is a commodity that frankly, is easy to acquire nowadays.</>
        ),
        mobile: (
          <>
            That&apos;{'s great because for us the money is a commodity that '}
            <Highlight2>frankly,</Highlight2> is easy to acquire nowadays.
          </>
        ),
      },
      description: `It’s much harder for you to find a work environment that you believe in and for us to find an engaged teammate.
      After six months at Ragnarson, you will set your salary to whatever you think is right, based on the company's budget, feedback from the people you have worked with and the market comparison.
      Nobody will have a right to interfere with that decision.`,
    },
  },
  sectionSkills: {
    title1: 'Required skills & experience',
    title2: 'Technology stack',
  },
  sectionCulture: {
    title: 'Culture & Perks',
    culture: {
      stayFresh: {
        vector: (
          <>
            <StayFresh />
          </>
        ),
        text: 'Stay Fresh',
      },
      alwaysLearning: {
        vector: (
          <>
            <AlwaysLearning />
          </>
        ),
        text: 'Always Learning',
      },
      healthcareFinancial: {
        vector: (
          <>
            <HealthCare />
          </>
        ),
        text: 'Healthcare Financial',
      },
      investmentTime: {
        vector: (
          <>
            <Time />
          </>
        ),
        text: 'Investment Time',
      },
    },
    description: `At Ragnarson we take the professional development of our team seriously and we always push to stay ahead of the curve.
    You will be exposed to the latest technologies, work on projects in a multitude of industries and develop valuable skills for years to come.`,
    readMore: 'Read More',
    getToKnowUs: {
      title: 'Get To Know Us',
      text: 'Hiring is a two way conversation – you’re interviewing us as well.',
      seeOurTeam: 'See our team',
    },
  },
  sectionProcess: {
    title: 'Our recruitment process',
    recuritmentProcess: [
      {
        Header: 'Getting to know you',
        Text: 'We send you a task/problem to solve, there is no time limit nor only one solution.',
      },
      {
        Header: 'Technical review',
        Text: 'We would like to get know you better, talk about your experience and expectations.',
      },
      {
        Header: 'Team talks',
        Text: 'We get back to you within a few days (sometimes hours) to get you on board.',
      },
      {
        Header: 'Decision',
        Text: 'We get back to you within a few days (sometimes hours) to get you on board.',
      },
      {
        Header: 'Onboarding',
        Text: 'We get back to you within a few days (sometimes hours) to get you on board.',
      },
    ],
    description: `At Ragnarson we take the professional development of our team seriously and we always push to stay ahead of the curve.
    You will be exposed to the latest technologies, work on projects in a multitude of industries and develop valuable skills for years to come.`,
  },
};

export default jobOfferData;
