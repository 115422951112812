import React from 'react';
import { useStateScreenMobile } from '@helpers/hooks';
import { Strings, StringsWrapper } from '@fragments';
import {
  SectionContainer,
  SectionTitle,
  SectionGrid,
  SectionRow,
  Left,
  Center,
  Right,
  RecuritmentProcessHeader,
  RecuritmentProcessText,
  SectionText,
  SeeDetailsButton,
} from './SectionProcess.styled';
import jobOfferData from '../JobOffer.data';

const {
  sectionProcess: { title, recuritmentProcess, description },
} = jobOfferData;

const SectionProcess = () => (
  <StringsWrapper>
    {useStateScreenMobile() ? (
      <Strings name="recuritment-process--mobile" width={360} height={739} top={114} mobile />
    ) : (
      <Strings name="recuritment-process" width={1442} height={1281} top={114} />
    )}
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      {!useStateScreenMobile() ? (
        <SectionGrid>
          <SectionRow>
            <Left />
            <Center space={5}>01.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[0].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[0].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Left>
              <RecuritmentProcessHeader>{recuritmentProcess[1].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[1].Text}</RecuritmentProcessText>
            </Left>
            <Center space={1}>02.</Center>
            <Right />
          </SectionRow>
          <SectionRow>
            <Left />
            <Center space={5}>03.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[2].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[2].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Left>
              <RecuritmentProcessHeader>{recuritmentProcess[3].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[3].Text}</RecuritmentProcessText>
            </Left>
            <Center space={1}>04.</Center>
            <Right />
          </SectionRow>
          <SectionRow>
            <Left />
            <Center space={5}>05.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[4].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[4].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
        </SectionGrid>
      ) : (
        <SectionGrid>
          <SectionRow>
            <Center space={0}>01.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[0].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[0].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Center space={0}>02.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[1].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[1].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Center space={0}>03.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[2].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[2].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Center space={0}>04.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[3].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[3].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
          <SectionRow>
            <Center space={0}>05.</Center>
            <Right>
              <RecuritmentProcessHeader>{recuritmentProcess[4].Header}</RecuritmentProcessHeader>
              <RecuritmentProcessText>{recuritmentProcess[4].Text}</RecuritmentProcessText>
            </Right>
          </SectionRow>
        </SectionGrid>
      )}
      <SectionText>{description}</SectionText>
      <SeeDetailsButton>See details</SeeDetailsButton>
    </SectionContainer>
  </StringsWrapper>
);

export default SectionProcess;
