import styled from 'styled-components';
import { Container } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const SectionWrapper = styled(Container)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(12.5)};
    margin-left: ${spaceDt(13)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(9)};
    margin-left: ${spaceMb(4)};
    margin-right: ${spaceMb(5)};
    padding-left: 0;
  }
`;

const SectionHead = styled.div`
  ${screenMin('lg')} {
    width: ${toVW(709, 'desktop')};
    margin-top: ${(props) => spaceDt(props.top)};
  }
  ${screenMax('lg')} {
    margin-top: ${(props) => spaceMb(props.topMobile)};
  }
  ${(props) => getTypography(props.font)};
`;

const SectionDescription = styled.div`
  ${screenMin('lg')} {
    width: ${toVW(917, 'desktop')};
    margin-top: ${(props) => spaceDt(props.top)};
  }
  ${screenMax('lg')} {
    margin-top: ${(props) => spaceMb(props.topMobile)};
  }
  ${(props) => getTypography(props.font)};
`;

const Highlight = styled.span`
  position: relative;
  z-index: 1;
  ${screenMin('lg')} {
    margin-right: ${spaceDt(2)};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${color.accent[2]};
    left: -2%;
    right: -3.53%;
    top: 18.31%;
    bottom: 3.48%;
    z-index: -1;
    transform: rotate(-3deg);
  }
`;

const Highlight2 = styled.span`
  position: relative;
  z-index: 1;
  ${screenMin('lg')} {
    margin-right: ${spaceDt(2)};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${color.accent[2]};
    left: -2%;
    right: -3.53%;
    top: 18.31%;
    bottom: 3.48%;
    z-index: -1;
    transform: rotate(3deg);
  }
`;

export { SectionWrapper, SectionHead, SectionDescription, Highlight, Highlight2 };
