import React from 'react';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionWrapper, SectionHead, SectionDescription } from './SectionOurApproach.styled';
import jobOfferData from '../JobOffer.data';

const {
  sectionOurApproach: { title, description, block1, block2, block3, block4 },
} = jobOfferData;

const SectionOurApproach = () => (
  <SectionWrapper>
    <SectionHead top={0} topMobile={0} font="heading-2">
      {title}
    </SectionHead>
    <SectionDescription top={5} topMobile={2} font="body-1">
      {description}
    </SectionDescription>

    <SectionHead top={15} topMobile={4} font="heading-3">
      {!useStateScreenMobile() ? block1.title.desktop : block1.title.mobile}
    </SectionHead>
    <SectionDescription top={10} topMobile={4} font="body-1">
      {block1.description}
    </SectionDescription>

    <SectionHead top={12} topMobile={4} font="heading-3">
      {!useStateScreenMobile() ? block2.title.desktop : block2.title.mobile}
    </SectionHead>
    <SectionDescription top={10} topMobile={4} font="body-1">
      {block2.description}
    </SectionDescription>

    <SectionHead top={20} topMobile={4} font="heading-3">
      {!useStateScreenMobile() ? block3.title.desktop : block3.title.mobile}
    </SectionHead>
    <SectionDescription top={10} topMobile={4} font="body-1">
      {block3.description}
    </SectionDescription>

    <SectionHead top={20} topMobile={4} font="heading-3">
      {!useStateScreenMobile() ? block4.title.desktop : block4.title.description}
    </SectionHead>
    <SectionDescription top={10} topMobile={4} font="body-1">
      {block4.description}
    </SectionDescription>
  </SectionWrapper>
);

export default SectionOurApproach;
