import React from 'react';
import { useStateScreenMobile } from '@helpers/hooks';
import PropTypes from 'prop-types';
import { SectionWrapper, SectionHalf, SectionTitle, SectionItem } from './SectionSkills.styled';
import jobOfferData from '../JobOffer.data';

const {
  sectionSkills: { title1, title2 },
} = jobOfferData;

const SectionSkills = ({ requiredSkills, techStacks }) => (
  <SectionWrapper>
    <SectionHalf>
      <SectionTitle>{title1}</SectionTitle>
      {requiredSkills.map((item, index) => (
        <SectionItem key={index} border={index % 2 === 0}>
          {item}
        </SectionItem>
      ))}
    </SectionHalf>
    <SectionHalf>
      <SectionTitle>{title2}</SectionTitle>
      {techStacks.map((item, index) => (
        <SectionItem key={index} border={index % 2 === (useStateScreenMobile() ? 0 : 1)}>
          {item}
        </SectionItem>
      ))}
    </SectionHalf>
  </SectionWrapper>
);

SectionSkills.propTypes = {
  requiredSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
  techStacks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SectionSkills;
