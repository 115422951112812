import React from 'react';
import JobOfferImage from '@images/JobOffer.png';
import { navigate } from 'gatsby';
import {
  SectionWrapper,
  SectionTitle,
  SectionGrid,
  SectionRow,
  SectionColumn,
  CultureVector,
  CultureText,
  SectionDescription,
  ReadMoreButton,
  SectionGetUsToKnow,
  GetUsToKnowImage,
  GetUsToKnowRightPane,
  GetUsToKnowTitle,
  GetUsToKnowText,
  SeeOurTeamButton,
} from './SectionCulture.styled';
import jobOfferData from '../JobOffer.data';

const {
  sectionCulture: { title, culture, description, readMore, getToKnowUs },
} = jobOfferData;

const SectionCulture = () => {
  const GotoTeam = () => {
    navigate('/team', { replace: true });
  };
  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>

      <SectionGrid>
        <SectionRow>
          <SectionColumn>
            <CultureVector>{culture.stayFresh.vector}</CultureVector>

            <CultureText>{culture.stayFresh.text}</CultureText>
          </SectionColumn>

          <SectionColumn>
            <CultureVector>{culture.alwaysLearning.vector}</CultureVector>
            <CultureText>{culture.alwaysLearning.text}</CultureText>
          </SectionColumn>
        </SectionRow>
        <SectionRow>
          <SectionColumn>
            <CultureVector>{culture.healthcareFinancial.vector}</CultureVector>

            <CultureText>{culture.healthcareFinancial.text}</CultureText>
          </SectionColumn>

          <SectionColumn>
            <CultureVector>{culture.investmentTime.vector}</CultureVector>
            <CultureText>{culture.investmentTime.text}</CultureText>
          </SectionColumn>
        </SectionRow>
      </SectionGrid>

      <SectionDescription>{description}</SectionDescription>

      <ReadMoreButton>{readMore}</ReadMoreButton>

      <SectionGetUsToKnow>
        <GetUsToKnowImage src={JobOfferImage} />
        <GetUsToKnowRightPane>
          <GetUsToKnowTitle>{getToKnowUs.title}</GetUsToKnowTitle>
          <GetUsToKnowText>{getToKnowUs.text}</GetUsToKnowText>
          <SeeOurTeamButton onClick={GotoTeam}>{getToKnowUs.seeOurTeam}</SeeOurTeamButton>
        </GetUsToKnowRightPane>
      </SectionGetUsToKnow>
    </SectionWrapper>
  );
};

export default SectionCulture;
