import styled, { css } from 'styled-components';
import { Container } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';

const SectionWrapper = styled(Container)`
  display: flex;
  flex-direction: row;

  ${screenMin('lg')} {
    margin-top: ${spaceDt(24.5)};
    margin-left: ${spaceDt(13)};
  }

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    padding-left: 0;
    flex-direction: column;
  }
`;

const SectionHalf = styled.div`
  ${screenMin('lg')} {
    width: 50%;
    padding-right: ${spaceDt(9.5)};
  }
  ${screenMax('lg')} {
    width: 100%;
    margin-top: ${spaceMb(10)};
    padding-right: ${spaceMb(5)};
  }
`;

const SectionTitle = styled.div`
  ${getTypography('heading-2')};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(8)};
  }

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(3)};
  }
`;

const SectionItem = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(3.5)};
    padding-left: ${spaceDt(2.5)};
  }

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(3)};
    padding-left: ${spaceMb(2.5)};
  }

  ${screenMin('lg')} {
    ${(props) =>
      props.border
        ? css`
            border-left: 10px solid ${color.bg.secondary};
          `
        : css`
            border-left: 10px solid white;
          `}
  }

  ${screenMax('lg')} {
    ${(props) =>
      props.border
        ? css`
            border-left: 4px solid ${color.bg.secondary};
          `
        : css`
            border-left: 4px solid white;
          `}
  }

  ${getTypography('heading-4')};
`;

export { SectionWrapper, SectionHalf, SectionTitle, SectionItem };
