import React from 'react';
import PropTypes from 'prop-types';
import {
  SectionWrapper,
  SectionTeam,
  SectionTitle,
  SectionSalary,
  SectionLocation,
  SectionDescription,
  SectionVector,
  SectionInfo,
  ApplyButton,
} from './SectionHeader.styled';

const SectionHeader = ({ team, title, minSalary, maxSalary, location, description }) => (
  <SectionWrapper>
    <SectionInfo>
      <SectionTeam>{team}</SectionTeam>
      <SectionTitle>{title}</SectionTitle>
      <SectionSalary>
        {minSalary} - {maxSalary} PLN
      </SectionSalary>
      <SectionLocation>{location}</SectionLocation>
      <SectionDescription>{description}</SectionDescription>
      <ApplyButton>Apply</ApplyButton>
    </SectionInfo>
    <SectionVector name="job-offer-header" />
  </SectionWrapper>
);

SectionHeader.propTypes = {
  team: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  minSalary: PropTypes.number.isRequired,
  maxSalary: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SectionHeader;
