import styled from 'styled-components';
import { Container, Button } from '@fragments';
import { color, getTypography, spaceMb, spaceDt, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Squirrel } from '@illustrations';

const SectionWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${screenMin('lg')} {
    margin-top: ${spaceDt(16.5)};
  }

  ${screenMax('lg')} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-top: ${spaceMb(2)};
  }
`;

const SectionTeam = styled.div`
  color: ${color.text.secondary};
  ${getTypography('heading-3')};
`;

const SectionTitle = styled.div`
  margin-top: ${spaceMb(1.5)};
  ${getTypography('heading-1')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(2.5)};
  }
`;

const SectionSalary = styled.div`
  margin-top: ${spaceMb(2)};
  ${getTypography('heading-3')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(2.5)};
  }
`;

const SectionLocation = styled.div`
  ${getTypography('heading-3')};
`;

const SectionDescription = styled.div`
  ${getTypography('heading-4')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    width: ${toVW(735, 'desktop')};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(2)};
  }
`;

const ApplyButton = styled(Button)`
  margin-top: ${spaceMb(2)};
  width: ${toVW(168, 'mobile')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    width: ${toVW(235, 'desktop')};
  }
`;

const SectionVector = styled(Squirrel)`
  width: ${toVW(200, 'mobile')};
  height: ${toVW(200, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(390, 'desktop')};
    height: ${toVW(390, 'desktop')};
  }
`;

export {
  SectionWrapper,
  SectionTeam,
  SectionTitle,
  SectionSalary,
  SectionLocation,
  SectionDescription,
  SectionVector,
  SectionInfo,
  ApplyButton,
};
