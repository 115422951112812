import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { VALIDATION_TYPES, INPUT_TYPES } from '@features/ContactForm/constants';
import SectionHeader from './children/SectionHeader';
import SectionOurApproach from './children/SectionOurApproach';
import SectionSkills from './children/SectionSkills';
import SectionCulture from './children/SectionCulture';
import SectionProcess from './children/SectionProcess';

const JobOffer = ({ pageContext: { job, topics } }) => {
  const applyFieldConfig = [
    { label: 'name', type: INPUT_TYPES.text, validationType: VALIDATION_TYPES.required },
    { label: 'email', type: INPUT_TYPES.text, validationType: VALIDATION_TYPES.email },
    {
      label: 'subject',
      type: INPUT_TYPES.picker,
      validationType: VALIDATION_TYPES.required,
      options: topics,
    },
    { label: 'message', type: INPUT_TYPES.multiline, validationType: VALIDATION_TYPES.required },
    { label: 'linkedin', type: INPUT_TYPES.text, validationType: VALIDATION_TYPES.required },
    {
      label: 'checkbox1',
      type: INPUT_TYPES.checkbox,
      validationType: VALIDATION_TYPES.checked,
      text: `I hereby give my consent to have my personal data, made available in the documents
      submitted by me in the job offer, processed for the needs of the actual recruitment process and recruitment process in future 12 months,
      in accordance with the General Data Protection Regulation (GDPR) 2016/679 of the European Parliament and of the Council of 27 April 2016`,
    },
    {
      label: 'checkbox2',
      type: INPUT_TYPES.checkbox,
      validationType: VALIDATION_TYPES.checked,
      text: `I hereby give my consent to have my personal data, made available in the documents
      submitted by me in the job offer, processed for the needs of the actual recruitment process and recruitment process in future 12 months,
      in accordance with the General Data Protection Regulation (GDPR) 2016/679 of the European Parliament and of the Council of 27 April 2016`,
    },
    { label: 'fileattachments', type: INPUT_TYPES.upload, validationType: VALIDATION_TYPES.attached },
  ];

  return (
    <>
      <SEO title={job.title.concat(' | Ragnarson')} description={job.description} />

      <SectionHeader
        team={job.team}
        title={job.title}
        minSalary={job.minSalary}
        maxSalary={job.maxSalary}
        location={job.location}
        description={job.description}
      />

      <SectionOurApproach />

      <SectionSkills requiredSkills={job.requiredSkills} techStacks={job.techStacks} />

      <SectionCulture />

      <SectionProcess />

      <ContactForm
        headline={<ContactFormHeader>Apply {job.title}</ContactFormHeader>}
        fieldsConfig={applyFieldConfig}
      />
    </>
  );
};

export default JobOffer;

JobOffer.propTypes = {
  pageContext: PropTypes.shape({
    job: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      team: PropTypes.string,
      mainTechStack: PropTypes.string,
      minSalary: PropTypes.number,
      maxSalary: PropTypes.number,
      location: PropTypes.string,
      requiredSkills: PropTypes.arrayOf(PropTypes.string),
      techStacks: PropTypes.arrayOf(PropTypes.string),
    }),
    topics: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
