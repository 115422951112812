import styled from 'styled-components';
import { Container, Button } from '@fragments';
import { spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

export const SectionContainer = styled(Container)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(12.5)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(10)};
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SectionTitle = styled.div`
  ${getTypography('heading-2')};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(10)};
  }

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-bottom: ${spaceMb(1)};
  }
`;

export const SectionGrid = styled.div`
  width: 100%;
  ${screenMin('lg')} {
    margin-top: ${spaceDt(15)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(2)};
    padding-right: ${spaceMb(2)};
  }
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;

  ${screenMin('lg')} {
    padding-left: ${spaceDt(13)};
    padding-right: ${spaceDt(13)};
    margin-bottom: ${spaceDt(1)};
  }

  ${screenMax('lg')} {
    align-items: center;
    margin-top: ${spaceMb(6)};
    padding-left: ${spaceMb(2)};
  }
`;

export const Left = styled.div`
  width: 40%;
`;

export const Center = styled.div`
  width: 20%;

  ${screenMin('lg')} {
    padding-left: ${(props) => spaceDt(props.space)};
    margin-top: ${spaceDt(3)};
    ${getTypography('heading-2')};
  }

  ${screenMax('lg')} {
    ${getTypography('heading-3')};
  }
`;

export const Right = styled.div`
  ${screenMin('lg')} {
    width: 40%;
  }

  ${screenMax('lg')} {
    padding-left: ${spaceMb(4)};
  }
`;

export const RecuritmentProcessHeader = styled.div`
  ${getTypography('heading-3')};
`;

export const RecuritmentProcessText = styled.div`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(1.5)};
  }
  ${screenMax('lg')} {
    margin-top: ${spaceMb(1)};
  }
  ${getTypography('body-1')};
`;

export const SectionText = styled.div`
  ${getTypography('heading-3')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(25)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(25)};
    padding-left: ${spaceMb(4)};
    padding-right: ${spaceMb(2)};
  }
`;

export const SeeDetailsButton = styled(Button)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    margin-bottom: ${spaceDt(12.5)};
    width: ${toVW(275, 'desktop')};
  }

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-top: ${spaceMb(2)};
    margin-bottom: ${spaceMb(10)};
    width: ${toVW(168, 'mobile')};
  }
`;
