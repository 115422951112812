import styled from 'styled-components';
import { Container, Button } from '@fragments';
import { spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const SectionWrapper = styled(Container)`
  ${screenMin('lg')} {
    margin-top: ${spaceDt(12.5)};
  }
  ${screenMax('lg')} {
    margin-top: ${spaceMb(5)};
    padding-left: 0;
    padding-right: 0;
  }
`;

const SectionTitle = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(10)};
  }
  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
  }
  ${getTypography('heading-2')};
`;

const SectionGrid = styled.div`
  ${screenMin('lg')} {
    width: 100%;
  }
  ${screenMax('lg')} {
    margin-left: ${spaceMb(2)};
  }
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;

  ${screenMax('lg')} {
    flex-direction: column;
  }
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${screenMin('lg')} {
    width: 50%;
  }

  ${screenMax('lg')} {
    width: 100%;
    margin-top: ${spaceMb(3)};
  }
`;

const CultureVector = styled.div`
  width: ${toVW(96, 'mobile')};
  height: ${toVW(96, 'mobile')};
  ${screenMin('lg')} {
    width: ${toVW(156, 'desktop')};
    height: ${toVW(156, 'desktop')};
  }
`;

const CultureText = styled.div`
  margin-left: ${spaceMb(1.5)};
  ${screenMin('lg')} {
    margin-left: ${spaceDt(5)};
  }
  ${getTypography('heading-3')};
`;

const SectionDescription = styled.div`
  ${screenMin('lg')} {
    margin-left: ${spaceDt(13)};
    margin-top: ${spaceDt(10)};
    width: ${toVW(810, 'desktop')};
  }

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
    margin-right: ${spaceMb(2)};
    margin-top: ${spaceMb(3)};
  }

  ${getTypography('heading-4')};
`;

const ReadMoreButton = styled(Button)`
  margin-top: ${spaceMb(2)};
  margin-left: ${spaceMb(4)};
  width: ${toVW(168, 'mobile')};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
    margin-left: ${spaceDt(13)};
    width: ${toVW(275, 'desktop')};
  }
`;

const SectionGetUsToKnow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${screenMin('lg')} {
    align-items: center;
    margin-top: ${spaceDt(12.5)};
  }

  ${screenMax('lg')} {
    margin-top: ${spaceMb(5)};
    flex-direction: column;
  }
`;

const GetUsToKnowImage = styled.img`
  ${screenMin('lg')} {
    width: ${toVW(496, 'desktop')};
    height: ${toVW(505, 'desktop')};
  }

  ${screenMax('lg')} {
    padding-left: ${spaceMb(2)};
    padding-right: ${spaceMb(2)};
    width: 100%;
  }
`;

const GetUsToKnowRightPane = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMin('lg')} {
    margin-left: ${spaceDt(4)};
  }

  ${screenMax('lg')} {
    margin-left: ${spaceMb(4)};
  }
`;

const GetUsToKnowTitle = styled.div`
  ${getTypography('heading-1')};

  ${screenMin('lg')} {
    width: ${toVW(385, 'desktop')};
    margin-bottom: ${spaceDt(3.5)};
  }

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(2)};
  }
`;

const GetUsToKnowText = styled.div`
  ${getTypography('heading-3')};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(5)};
  }

  ${screenMax('lg')} {
    width: ${toVW(260, 'mobile')};
    margin-bottom: ${spaceMb(2)};
  }
`;

const SeeOurTeamButton = styled(Button)`
  ${screenMin('lg')} {
    width: ${toVW(275, 'desktop')};
  }

  ${screenMax('lg')} {
    width: ${toVW(168, 'mobile')};
  }
`;

export {
  SectionWrapper,
  SectionTitle,
  SectionGrid,
  SectionRow,
  SectionColumn,
  CultureVector,
  CultureText,
  SectionDescription,
  ReadMoreButton,
  SectionGetUsToKnow,
  GetUsToKnowImage,
  GetUsToKnowRightPane,
  GetUsToKnowTitle,
  GetUsToKnowText,
  SeeOurTeamButton,
};
